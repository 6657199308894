@use 'sass:math';
@use 'src/assets/styles/mixins.scss';

.serviceCard {
  width: calc(100% / 2 - 1.6rem);

  @include mixins.mediaSmall {
    width: calc(100% / 2 - 0.8rem);
  }
  &.isGrid {
    // max-width: calc(25% - 0.8rem);
    width: 100%;
    @include mixins.mediaSmall {
      margin-bottom: 2rem;
    }
  }
}

.serviceCardImageStack {
  position: relative;
  width: 100%;
  height: 20rem;
  overflow: hidden;
  border-radius: 8px;

  @include mixins.mediaSmall {
    height: 12rem;
  }

  &[data-size='true'] {
    @include mixins.mediaSmall {
      height: 20rem;
    }
  }
}

.serviceCardTax {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: var(--black);
  color: var(--white);
  padding: 0.8rem 1rem;
  line-height: 1;
  font-weight: 500;
  font-size: 1.2rem;
  border-radius: 0 0 4px 0;
}

.serviceCardImage {
  width: 100%;
  height: 100%;

  * {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
    display: block;
  }
}

.serviceCardPickup {
  position: absolute;
  bottom: 1.6rem;
  right: 1.6rem;
  z-index: 1;

  @include mixins.mediaSmall {
    bottom: 1rem;
    right: 1rem;
  }
}

.serviceCardHeading {
  font-size: 1.6rem;
  line-height: 1.4;
  font-weight: 600;
  min-height: 4.2rem;
  margin-top: 1.2rem;

  @include mixins.mediaSmall {
    height: auto;
    min-height: 4rem;
  }
}

.serviceCardPrice {
  line-height: 1;
  font-size: 1.4rem;
  font-weight: 600;
  color: var(--red);
  margin-top: 1rem;

  @include mixins.mediaSmall {
    font-size: 1.2rem;
    line-height: 1.3;
  }
}

.serviceCardSpot {
  display: flex;
  align-items: center;
  margin-top: 1.4rem;
  padding-top: 1.4rem;
  border-top: 1px solid var(--lightGrey);
}

.serviceCardSpotImage {
  width: 2.8rem;
  height: 2.8rem;
  overflow: hidden;
  border-radius: 100px;
  flex: none;

  * {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
    display: block;
  }
}

.serviceCardSpotName {
  line-height: 1.5;
  font-size: 1.2rem;
  margin-left: 0.8rem;
}
