@use 'sass:math';
@use 'src/assets/styles/mixins.scss';

.pickupLabel {
  line-height: 1;
  background-color: #fff532;
  font-weight: 600;
  padding: 0.6rem 0.8rem;
  border-radius: 2px;
  font-size: 1.2rem;
}
