@use 'sass:math';
@use 'src/assets/styles/mixins.scss';

.container {
  max-width: 104rem;
  min-height: 40vh;
  min-height: 40dvh;
  margin-top: 8rem;
  margin-left: auto;
  margin-right: auto;

  @include mixins.mediaSmall {
    max-width: initial;
    margin-top: 7.2rem;
  }
}

.pageFadeInContainer {
  height: 100%;
  opacity: 0;
  will-change: opacity;

  &[data-domload='true'] {
    opacity: 0;
  }

  &[data-domload='false'] {
    animation: fade-in 0.15s forwards 1s;
  }
}

.pageFadeLoad {
  max-width: 104rem;
  margin-top: 8rem;
  margin-left: auto;
  margin-right: auto;

  @include mixins.mediaSmall {
    max-width: initial;
    margin-top: 7.2rem;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.containerIsMtNone {
  margin-top: 0;

  @include mixins.mediaSmall {
    margin-top: 0;
  }
}
