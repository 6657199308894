@use 'sass:math';
@use 'src/assets/styles/mixins.scss';

.top {
  overflow: hidden;
  padding-top: 8rem;

  @include mixins.mediaSmall {
    padding-top: 7.2rem;
  }
}

.topCta {
  margin-top: 10rem;

  @include mixins.mediaSmall {
    margin-top: 8rem;
  }
}

// -------------------------

.topKv {
  // height: 80rem;
  padding: 5.2rem 0;
  background-color: #eee;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;

  @include mixins.mediaSmall {
    // height: 60rem;
  }
}

.topKvAssets {
  position: absolute;
  height: auto;
  z-index: 1;

  @include mixins.mediaSmall {
    display: none;
  }

  &:nth-of-type(1) {
    width: 23.8rem;
    bottom: 6rem;
    left: 0;
  }

  &:nth-of-type(2) {
    width: 18rem;
    top: 15rem;
    right: -2rem;
  }

  svg {
    display: block;
    width: 100%;
  }
}

.topKvInner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
}

.topKvLogo {
  width: 28rem;
  height: auto;

  @include mixins.mediaSmall {
    width: 20rem;
  }

  * {
    display: block;
    width: 100% !important;
    height: 100% !important;
  }
}

.topKvText {
  font-size: 3.4rem;
  line-height: 1.5;
  font-weight: 700;
  text-align: center;
  color: var(--white);
  margin-top: 1.2rem;
  letter-spacing: 0.03em;

  @include mixins.mediaSmall {
    font-size: 2.4rem;
    padding: 0 2rem;
    line-height: 1.4;
  }
}

.topKvSubText {
  font-size: 1.6rem;
  line-height: 1.6;
  text-align: center;
  color: var(--white);
  margin-top: 0.6rem;

  br {
    @include mixins.mediaSmall {
      display: none;
    }
  }

  @include mixins.mediaSmall {
    font-size: 1.4rem;
    padding: 0 2rem;
    line-height: 1.6;
    margin-top: 1.6rem;
  }
}

.topKvLinkWrap {
  display: flex;
  align-items: center;
  gap: 0 1.2rem;
  margin-top: 3.2rem;
  margin-bottom: 2rem;

  @include mixins.mediaSmall {
    margin-top: 2.8rem;
    flex-direction: column;
    gap: 2.4rem;
    padding: 2.4rem 2rem 2.8rem;
    border: 2px solid var(--white);
    border-radius: 8px;
  }
}

.topKvLink {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  span {
    line-height: 1;
    font-size: 1.4rem;
    color: var(--white);
    font-weight: 600;
    text-align: center;
  }

  a {
    display: flex;
    align-items: center;
    width: 28rem;
    height: 5.6rem;
    background-color: var(--white);
    color: var(--blue);
    font-weight: 600;
    justify-content: center;
    border-radius: 30px;
    font-size: 2rem;
    line-height: 1;

    @include mixins.mediaSmall {
      width: 32rem;
    }
  }
}

.topKvAboutLink {
  font-size: 1.4rem;
  font-weight: 600;
  text-decoration: underline;
  color: var(--white);
  letter-spacing: 0.05em;
  margin-top: 1.6rem;

  @include mixins.mediaSmall {
    font-size: 1.6rem;
  }
}

.topKvBg {
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;

  * {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
  }
}

// -------------------------

.topSection {
  width: 100%;

  &:not(:first-of-type) {
    margin-top: 12rem;
    position: relative;

    @include mixins.mediaSmall {
      margin-top: 5.2rem;
    }
  }
}

.topContainer {
  max-width: 104rem;
  margin-left: auto;
  margin-right: auto;

  @include mixins.mediaSmall {
    max-width: initial;
    padding: 0 2rem;
  }
}

.topSectionHeading {
  text-align: center;
  line-height: 1;
  font-weight: 700;
  color: var(--blue);
  margin-top: 4rem;
  font-size: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;

  @include mixins.mediaSmall {
    font-size: 2.8rem;
    line-height: 1.2;
    margin-top: 2rem;
  }
}

.topSectionHeadingAppeal {
  background: var(--blue);
  color: var(--white);
  font-size: 2.4rem;
  font-weight: 600;
  border-radius: 0.5rem;
  padding: 1rem;
  margin-right: 0.8rem;

  @include mixins.mediaSmall {
    font-size: 1.8rem;
    padding: 0.8rem;
  }
}

.topSectionText {
  text-align: center;
  margin-top: 1.6rem;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.7;

  @include mixins.mediaSmall {
    font-weight: 300;
    text-align: left;
  }
}

.topSectionInfo {
  text-align: center;
  margin-top: 1.4rem;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.7;
  color: var(--blue);

  @include mixins.mediaSmall {
    font-weight: 300;
    text-align: left;
  }
}

.topSectionLabel {
  line-height: 1;
  font-size: 2rem;
  font-weight: 500;
  letter-spacing: 0.05em;
  text-align: center;
  margin-bottom: 2rem;

  @include mixins.mediaSmall {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  span {
    color: var(--blue);
  }
}

// -------------------------

.topTickets {
  max-width: 96rem;
  width: 100%;
  margin: 5.2rem auto -2.4rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;

  @include mixins.mediaSmall {
    max-width: initial;
    margin-bottom: -0.8rem;
    padding: 0 2rem;
    gap: 0;
    justify-content: space-between;
  }

  // &::after {
  //   content: '';
  //   display: block;
  //   width: calc(100% / 4 - 1.6rem);

  //   @include mixins.mediaSmall {
  //     display: none;
  //   }
  // }

  @include mixins.mediaSmall {
    max-width: initial;
    margin-top: 4rem;
  }
}

// -------------------------

.topService {
  margin-top: 6rem;
  padding: 0 20rem;
  overflow: hidden;

  @include mixins.mediaSmall {
    overflow: visible;
    padding: 0 4rem;
    margin-top: 4.8rem;
  }
}
.topServiceHeading {
  line-height: 1.5;
  font-size: 3.6rem;
  font-weight: 600;
  text-align: center;
  letter-spacing: 0.05em;
  margin: 4rem auto 2.4rem;

  @include mixins.mediaSmall {
    font-size: 2.4rem;
    line-height: 1.4;
  }
}

.topServiceGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 2rem;
  @include mixins.mediaSmall {
    grid-template-columns: 1fr;
    gap: 2.4rem;
  }
}

.topServiceButton {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4.8rem;

  @include mixins.mediaSmall {
    margin-top: 3.2rem;
  }
}

// -------------------------

.topHotels {
  margin-top: 6rem;
  padding: 0 20rem;
  overflow: hidden;

  @include mixins.mediaSmall {
    overflow: visible;
    padding: 0 4rem;
    margin-top: 4.8rem;
  }
}

.topHotelsButton {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4.8rem;

  @include mixins.mediaSmall {
    margin-top: 3.2rem;
  }
}

// -------------------------

.topAbout {
  background-color: var(--white);
  padding: 10rem 0;
  margin-top: 10rem;

  @include mixins.mediaSmall {
    padding: 6rem 0;
    margin-top: 6rem;
  }
}

.topAboutContainer {
  max-width: 104rem;
  margin-left: auto;
  margin-right: auto;

  @include mixins.mediaSmall {
    max-width: initial;
    padding: 0 2rem;
  }
}

.topAboutHeading {
  line-height: 1.5;
  font-size: 3.6rem;
  font-weight: 600;
  text-align: center;
  letter-spacing: 0.05em;

  @include mixins.mediaSmall {
    font-size: 2.4rem;
    line-height: 1.4;
  }
}

.topAboutText {
  line-height: 1.5;
  font-size: 1.6rem;
  text-align: center;
  margin-top: 0.5rem;

  @include mixins.mediaSmall {
    font-size: 1.4rem;
    line-height: 1.4;
  }
}

.topAboutHead {
  margin-top: 6rem;
  position: relative;

  @include mixins.mediaSmall {
    margin-top: 3.2rem;
  }
}

.topAboutHowToUse {
  width: 100%;
  padding-top: 10rem;
  margin-top: 10rem;
  border-top: 1px solid var(--lightGrey);

  @include mixins.mediaSmall {
    padding-top: 6rem;
    margin-top: 6rem;
  }
}

// -------------------------

.topFaQ {
  margin-top: 10rem;
  width: 100%;

  @include mixins.mediaSmall {
    margin-top: 6rem;
    padding: 0 2rem;
  }
}

.topFaQHeading {
  line-height: 1;
  font-size: 3.2rem;
  font-weight: 600;
  color: var(--blue);
  letter-spacing: 0.05em;
  text-align: center;
  margin-bottom: 5.2rem;

  @include mixins.mediaSmall {
    font-size: 2.4rem;
    margin-bottom: 3.2rem;
  }
}

// -------------------------

.topRecommend {
  padding: 10rem 0;
  margin-top: 10rem;
  background-color: var(--white);
  width: 100%;
  overflow: hidden;

  @include mixins.mediaSmall {
    padding: 6rem 3.2rem;
    margin-top: 6rem;
  }
}

.topRecommendHeading {
  text-align: center;
  font-size: 2.8rem;
  font-weight: 500;
  letter-spacing: 0.05em;
  line-height: 1;

  @include mixins.mediaSmall {
    font-size: 2.4rem;
  }
}

.topRecommendCarousel {
  margin-top: 5.2rem;

  @include mixins.mediaSmall {
    margin-top: 3.2rem;
  }
}
