@use 'sass:math';
@use 'src/assets/styles/mixins.scss';

.passInfomation {
  @include mixins.mediaSmall {
    margin: 0 2rem;
  }
}
.passInfomation__Wrapper {
  background: #f1e7e9;
  padding: 1.2rem 1.8rem;
  border-radius: 0.6rem;
}

.passInfomation__Text {
  font-size: 1.4rem;
  line-height: 1.3;
  color: var(--red);
}
